import Cookies from 'js-cookie'
import ServicioApi from '~/services/auth'
import general from '~/mixins/general'
import Helper from '~/services/helper'

const TOKEN = 'token'

export const state = {
    token: Cookies.get(TOKEN),
    usuario: {},
    error: {},
    colorSistema: '#00125A',
    parametros: {
        login_tema: 0,
        bienvenida_titulo: 'Bienvenido al E-learning MAC',
        bienvenida_texto: 'Actualice y refuerce sus conocimientos sobre nuestros servicios',
        logo_firmado: '/img/essi.svg',
        fondo_firmado: '/img/backgrounds/background_login.png',
    },
    redes:[]
}

export const getters = {
    obtenerUsuario: ({ usuario })  => usuario,
    obtenerToken: ({ token }) => token,
    obtenerError: ({ error }) => error,
    existeToken: ({ token }) => !_.isEmpty(token),
    existeUsuario: ({ usuario }) => !_.isEmpty(usuario) && !!usuario.id,
    getColorSistema: ({colorSistema}) => colorSistema,
    parametros: ({parametros}) => parametros,
    redes: ({redes}) => redes,
}

export const mutations = {
    ALMACENAR_TOKEN: (state, payload) => {
        state.token = payload
        Cookies.set(TOKEN, payload)
    },
    ALMACENAR_USUARIO: (state, payload) => {
        state.usuario = payload
    },
    ALMACENAR_ERROR: (state, { response: { data } } ) => {
        state.error = {
            ...data,
            tipo: data.codigo ? 400 : 500
        }
        Helper.message(data.error, "error");
    },
    CERRAR_SESION: state => {
        localStorage.clear()
        Cookies.remove(TOKEN)
        state.usuario = {}
        state.token = '',
        state.tokenGoogle = {}
        state.error = {}
    },
    REMOVER_ERRORES: state => {
        state.error = {}
    },
    setColorSistema: (state, payload) => {
        state.colorSistema = payload
    },
    setParametros: (state, payload) => {
        state.parametros = payload;
    },
    setRedes: (state, payload) => {
        state.redes = payload;
    },
    cambiarColorSistema(state){
        if (state.colorSistema){
            document.body.style.setProperty('--color-general', state.colorSistema);
            document.body.style.setProperty('--gr-general', `linear-gradient(to right, #0A0A50, ${state.colorSistema})`);

        }
    }
}

export const actions = {
    async intentarIniciarSesion({ commit, dispatch }, payload){
        commit('REMOVER_ERRORES')
        const { data: { data : { token, usuario } } } = await ServicioApi.iniciarSesion(payload)

        // dispatch('consultarRoles')

        commit('ALMACENAR_TOKEN', token)
        commit('ALMACENAR_USUARIO', usuario)
    },
    async consultarUsuario({ commit, dispatch }){
        const { data: { data :{ usuario } } } = await ServicioApi.obtenerUsuario()
        commit('ALMACENAR_USUARIO', usuario)
        dispatch('getParametros')
        // Aws.setBucket(usuario.bucket)
    },
    async getParametros({ commit }){
        const { data: { data } } = await ServicioApi.getParametros()
        commit('setColorSistema', data.parametros.color)
        commit('setParametros', data.parametros)
        commit('setRedes', data.redes)
        commit('cambiarColorSistema')
    },
    async consultarRoles({ commit }){
        const { data: { roles } } = await ServicioApi.obtenerRoles()
        commit('ALMACENAR_ROLES', roles)
    },
    async intentarCerrarSesion({ commit }){
        await ServicioApi.cerrarSesion()
        commit('CERRAR_SESION')
        commit('REMOVER_ERRORES')
    }
}
