import { mapGetters } from 'vuex'
import Helper from '~/services/helper'
import Aws from '~/services/aws'
import General from "~/services/general";


export default {
    computed: {
        ...mapGetters({
            $usuario: 'auth/obtenerUsuario',
        })
    },
    data(){
        return{
            dialogObj: {},
            tipo_chat : null,
            cant_caracteres: 86,
            cargandoRecursos: false,
        }
    },
    methods: {
        $validar(a){
            Helper.validar(a)
        },
        error_catch(e,referenciaValidador = null){
            Helper.error_catch(e)
            const codigosError = [400,422]
            if(e.response && codigosError.includes(e.response.data.status)){
                console.log('aquiiiii 400');
                Helper.message(`${e.response.data.message}`, "warning");
            }else{
                Helper.message(`Error, ${e.response.data.message}`,"error");            
            }

            if(this.existenValidaciones(e) && referenciaValidador){
                this.$refs[referenciaValidador].setErrors(e.response.data.validaciones)
            }
        },
        existenValidaciones: excepcion => !_.isEmpty(excepcion?.response?.data?.validaciones),
        confirmar(message,title,callback = false,callback2 = false){
            this.$confirm(message,title,{
                confirmButtonText: 'Si',
                cancelButtonText: 'No',
                type: 'warning',
                center: true,
                dangerouslyUseHTMLString: true
            }).then(() => {
                if(callback) callback()
            }).catch(() => {
                if(callback2) callback2()
            })
        },
        aceptar(message,title,callback = false){
            this.$confirm(message,title,{
                showCancelButton: false,
                confirmButtonText: 'Aceptar',
                // cancelButtonText: 'No',
                type: 'warning'
                // center: true,
            }).then(() => {
                if(callback) callback()
            })
        },
        notificacion(title,message,type = 'info',isHtml = true){
            Helper.message(message,type)
            // Helper.notificacion(title,message,type,isHtml)
        },
        notify(message,type = 'info',isHtml = true){
            Helper.message(message,type,isHtml)
        },
        crear_formData(data){
            const fd = new FormData()
            for(var key in data){
                if(Array.isArray(data[key])){
                    for(var key2 in Object.entries(data[key])){
                        fd.append(`${key}[${key2}]`,data[key][key2])
                    }
                } else {
                    fd.append(key,data[key])
                }
            }
            return fd
        },
        icon_extension(fileName){
            const fileTypes = {
                image: {
                    extension: ['bmp','gif','jpg','jpeg','png','svg','webp'],
                    icon: 'icon-file-image'
                },
                pdf: {
                    extension: ['pdf'],
                    icon: 'icon-pdf'
                },
                video: {
                    extension: ['avi','mp4','mpg','mpeg','wmv','div'],
                    icon: 'icon-video '
                },
                other: {
                    icon: 'icon-file-multiple'
                }
            }
            const fileType = fileName.split('.').pop()
            const isImage = fileTypes.image.extension.includes(fileType.toLowerCase())
            const isPdf = fileTypes.pdf.extension.includes(fileType.toLowerCase())
            const isVideo = fileTypes.video.extension.includes(fileType.toLowerCase())
            if(isImage){
                return fileTypes.image.icon
            } else if(isPdf){
                return fileTypes.pdf.icon
            } else if(isVideo){
                return fileTypes.video.icon
            } else {
                return fileTypes.other.icon
            }
        },
        file_type(fileName){
            const fileTypes = {
                image: {
                    extension: ['bmp','gif','jpg','jpeg','png','svg','webp']
                },
                pdf: {
                    extension: ['pdf']
                },
                video: {
                    extension: ['mp4']
                    // extension: ['avi','mp4', 'mpg', 'mpeg', 'wmv', 'div'],
                }
            }
            const fileType = fileName.split('.').pop()
            const isImage = fileTypes.image.extension.includes(fileType.toLowerCase())
            const isPdf = fileTypes.pdf.extension.includes(fileType.toLowerCase())
            const isVideo = fileTypes.video.extension.includes(fileType.toLowerCase())
            if(isImage){
                return 'image/*'
            } else if(isPdf){
                return 'application/pdf'
            } else if(isVideo){
                return 'video/*'
            } else {
                return '*'
            }
        },

        /*
            Otorga el focus al input indicado por referencia dentro de un popover

            @Params:
            * referencia = String (nombre de la referencia del input)
            * index = Entero (índice de la iteración, en caso de estar en un v-for)
        */
        autofocus_popover(referencia,index = 0){
            setImmediate(x => {
                this.$nextTick(() => {
                    const input = this.$refs[referencia]

                    if(input.$el){this.$refs[referencia].$el.children[0].focus()}

                    if(input.length > 0) input[index].focus()
                    else input.focus()
                })
            })
        },

        async uploadCropperImage(file, path = 'img'){
            if(file === null){
                return null
            }
            if(file.image === null){
                return null
            }
            const archivo = Aws.b64toBlob(file.image)
            const name = file.name
            let ruta = `Market/${path}/${Aws.getHash()}.${name.split('.').pop()}`
            const resp = await Aws.getInstance().upload({
                Key: ruta,
                ContentType: archivo.type,
                Body: archivo
            }).on('httpUploadProgress',uploadEvent=>{
                let porcentaje = Math.round(uploadEvent.loaded / uploadEvent.total * 100);
                this.mensaje = porcentaje
            }).promise()

            return resp.Key
        },
        /**
         * Función para generar un delay
         * usada en un input tipo buscador
         * @Params:
            * callback = funcion
            * ms = duración en milisengundos del retraso
         */
        delay(callback,ms = 600){
            if(window.retraso) clearInterval(window.retraso)
            window.retraso = setTimeout(callback,ms)
        },

        rutaImagenMapa: () => '/img/otros/mapa-preview.png',

        /** Notificaciones para Chat
         * Tipo:
         *      1 Soporte cliente
         *          casos:
         *          -/admin/soporte/soporte-general (Tab soporte general caht iniciado desde móvil),
         *      2 Pedidos,
         *      3 Tiendas(soporte de un tendero a admin)
        */
        notifiChat(tipo=2, chat={}, info={}){
            try {
                this.tipo_chat = tipo
                let icon = 'icon-message-text'
                let id = null
                let myMensaje = null
                let tituloSpan = null
                let msg = ''
                if(chat.tipo == 1){
                    msg = chat.mensaje
                } else if(chat.tipo == 2){
                    msg = '(Imágen)'
                } else {
                    msg = '(Pdf)'
                }
                switch(tipo){
                case 1:
                    icon = 'icon-message-text'
                    if(msg.length > this.cant_caracteres){
                        msg = msg.slice(0,this.cant_caracteres) + '...';
                    }
                    tituloSpan = `Mensaje soporte cliente`
                    break;
                case 2:
                    icon = 'icon-message-text'
                    id = info.id

                    myMensaje = `${info.nombre ? info.nombre : ''}: ${msg}`
                    if(myMensaje.length > this.cant_caracteres){
                        myMensaje = myMensaje.slice(0,this.cant_caracteres) + '...';
                    }
                    tituloSpan = `Nuevo mensaje -ID ${id}`
                    break;
                case 3:
                    icon = 'icon-lifebuoy'
                    if(msg.length > this.cant_caracteres){
                        msg = myMensaje.slice(0,this.cant_caracteres) + '...';
                    }
                    tituloSpan = `Nuevo mensaje`
                    break;
                default:
                    break;
                }
                const h = this.$createElement
                this.$notify({
                    title: '',
                    dangerouslyUseHTMLString: true,
                    message: h('div',{class: ''},[
                        h('p',{class: ''},[
                            h(
                                'i',
                                {
                                    class: `${icon} text-gr-general cr-pointer`,
                                    on: {
                                        click: this.doSomeThing
                                    }
                                },
                            ),
                            h(
                                'span',
                                {
                                    class: `f-600`,
                                },
                                `${tituloSpan}`
                            ),
                            h(
                                'br',
                            ),
                            h(
                                'p',
                                `${msg}`
                            ),
                        ]),
                    ]),
                    // position: 'bottom-left'
                });

            } catch (e){
                this.error_catch(e)
            }

        },
        doSomeThing(){
            // console.log('doSomeThing',this.tipo_chat);
        },
        /**
         * Método para hacer descarga del un archivo zip
         * @param {*} data Respuesta la petición
         * @param {string} name Nombre del archivo zip
         */
        descargarZip(data, name){
            const blob = new Blob([data],{type:'application/zip'});
            let url = window.URL.createObjectURL(blob)
            let anchor = document.createElement("a");
            anchor.download = name;
            anchor.href = url;
            anchor.click()
        },
        async downloadURI(dataurl, name){
            try {
                this.cargandoRecursos = this.$loading({
                    lock: true,
                    text: "El archivo se está descargando, por favor no cierre o actualice ésta página. Este proceso puede tardar unos segundos.",
                    spinner: "icon-spin6 rotating f-40",
                    background: "rgba(0, 0, 0, 0.7)",
                });
                const { data, headers } = await General.descargar(dataurl)
                let blob = new Blob([data], { type: headers['content-type'] });
                let link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = name
                link.click()
            } catch (error){
                error_catch(error)
            } finally {
                this.cargandoRecursos.close()
            }
        },

    }
}
