<template>
    <div :class="clase">
        {{ mensaje }}
    </div>
</template>
<script>
export default {
    props:{
        mensaje:{
            type:String,
            default:'Sin datos'
        },
        clase:{
            type:String,
            default:'row justify-content-center'
        }
    }
}
</script>