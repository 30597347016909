import { Notification, Message } from "element-ui";
import axios from 'axios'
export const getHostname = window.location.hostname
// eslint-disable-next-line new-cap
export const getAxios = () => new axios.create()
const Helper = {    
    notificacion(title,message,type = 'info',isHtml = false){
        Notification({
            title: title,
            message: message,
            type: type,
            dangerouslyUseHTMLString: isHtml
        })
    },
    message(message,type = 'success',isHtml){
        Message({
            message: message,
            type: type,
            dangerouslyUseHTMLString:isHtml
        });
    },
    error_catch(e){
        console.error(e)
    }
}

export default Helper
