import { render, staticRenderFns } from "./modalVideo.vue?vue&type=template&id=da1ea192&scoped=true&"
import script from "./modalVideo.vue?vue&type=script&lang=js&"
export * from "./modalVideo.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "da1ea192",
  null
  
)

export default component.exports