import Vue from 'vue'
import lodash from 'lodash'
import './vee-validate'
import './draggable'
import './slim'
import './vue-quill'
import General from '~/mixins/general'
import Formateadores from '~/mixins/Formateadores'

// import moment from 'moment'
// moment.updateLocale('es', {
//     week: { dow: 0 }
// })
Vue.prototype._ = lodash
Vue.mixin(General);
Vue.mixin(Formateadores);
