import axios from 'axios';

const API_PREFIX = '/auth';

const ENDPOINTS = {
    iniciarSesion: payload => axios.post(`${API_PREFIX}/login`, payload),
    cerrarSesion: payload => axios.post(`${API_PREFIX}/logout`, payload),
    obtenerUsuario: () => axios(`${API_PREFIX}/usuario`),
    getParametros: () => axios(`${API_PREFIX}/parametros`),
    recuperarPassword: payload => axios.put(`${API_PREFIX}/recuperar-password`,payload),

};

export default ENDPOINTS;
