export default [
    {
        path: '/foro/:id_curso',
        name: 'foro',
        component: () => import(/* webpackChunkName: "for" */ '../../pages/foro').then(m => m.default || m),
    },
    {
        path: '/foro/detalle/:id_foro',
        name: 'foro.detalle',
        component: () => import(/* webpackChunkName: "foro" */ '../../pages/foro/foroDetalle').then(m => m.default || m),
    },
]