import axios from 'axios';

const API_PREFIX = 'lector';

const ENDPOINTS = {
    obtenerCursosUsuario: () => axios(`${API_PREFIX}/mis-cursos`),
    obtenerCursosUsuarioMosaico: () => axios(`${API_PREFIX}/mis-cursos-mosaico`),
    obtenerCursosCarpetaVer: (payload) => axios(`${API_PREFIX}/carpetas/${payload.id_carpeta}`),
    obtenerTodasCarpetas: () => axios(`${API_PREFIX}/carpetas`),
};

export default ENDPOINTS;
