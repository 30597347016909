<template>
    <div ref="aqui" class="_df_custom" />
</template>

<script>
export default {
    name:'Dflip',
    props:{
        source:{
            type: String,
            default:null
        },
        config:{
            type:Object,
            default:()=>{}
        }

    },
    data(){
        return {
            instance:null,
            options:{}
        }
    },
    watch:{
        source(a,b){
            if(a){
                this.instance = null;
                this.instance = $(this.$refs.aqui).flipBook(this.source, this.options);
            }
        }
    },
    mounted(){

        let that = this;
        this.options = {
            webgl: false,
            enableDebugLog: false,
            autoEnableOutline: false,
            autoEnableThumbnail: true,
            enableDownload: false,
            allControls: "altPrev,pageNumber,altNext,play,outline,thumbnail,zoomIn,zoomOut,fullScreen,download,more,pageMode,startPage,endPage,sound",
            singlePageMode: window.DFLIP.SINGLE_PAGE_MODE.ZOOM,
            pageSize: window.DFLIP.PAGE_SIZE.AUTO,
            hard: 'none',
            text: {
                toggleSound: "Encender/apagar sonido",
                toggleThumbnails: "Ver miniaturas",
                toggleOutline: "Toggle Outline/Bookmark",
                previousPage: "Atrás",
                nextPage: "Siguiente ",
                toggleFullscreen: "Pantalla completa",
                zoomIn: "Zoom In",
                zoomOut: "Zoom Out",
                toggleHelp: "Toggle Help",

                singlePageMode: "Modo página única",
                doublePageMode: "Modo página doble",
                downloadPDFFile: "Download PDF File",
                gotoFirstPage: "Ir primera página",
                gotoLastPage: "Ir última página",
                play: "Automático",
                pause: "Pause",
                onCreate(flipBook){
                    that.$emit('on:create',flipBook)
                },
                onCreateUI(flipBook){
                    that.$emit('on:create:ui',flipBook)
                },
                onFlip(flipBook){
                    that.$emit('on:flip',flipBook)
                },
                beforeFlip(flipBook){
                    that.$emit('before:flip',flipBook)
                },
                onReady(flipBook){
                    that.$emit('on:ready',flipBook)
                },
            },
            ...this.config,
        };
        console.log(this.$refs.aqui,'1');
        if(this.source) this.instance = $(this.$refs.aqui).flipBook(this.source, this.options);
        console.log(this.$refs.aqui,'2');
    }
}
</script>
