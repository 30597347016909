import Cursos from './cursos/cursos';
import Configurar from './configurar/configurar';
import Login from './auth/login';
import Foro from './foro/foro';
import Mensajes from './mensajes/mensajes';
import Responsable from './responsable/responsable';


export default [
    {
        path: '/',
        name: 'home',
        component: () => import('../pages/Home.vue').then(m=>m.default || m),
        redirect: {name: 'mis-cursos'},
        children: [
            {
                path: '/mis-cursos/:tipo_vista?',
                name: 'mis-cursos',
                component: () => import(/* webpackChunkName: "mis-cursos" */ '../pages/misCursos').then(m => m.default || m),
            },

            {
                path: '/configurar',
                name: 'configurar',
                component: () => import(/* webpackChunkName: "configurar" */ '../pages/configurar').then(m => m.default || m),
            },
            {
                path: '/responsable/:id_carpeta?',
                name: 'responsable',
                component: () => import(/* webpackChunkName: "Responsable" */ '../pages/responsable').then(m => m.default || m),
            },
            {
                path: '/mis-cursos/mosaico',
                name: 'mis-cursos.mosaico',
                component: () => import(/* webpackChunkName: "mis-cursos" */ '../pages/misCursos/mosaico').then(m => m.default || m),
            },   
        ],
    },
    ...Cursos,
    ...Login,
    ...Foro,
    ...Configurar,
    ...Responsable,
    ...Mensajes
]
