import Axios from "axios";

const RUTA = '/notificaciones'

const END_POINTS = {
    listarNotificaciones: () => Axios.get(`${RUTA}/listar`),
    contarNotificaciones: () => Axios.get(`${RUTA}/contar`),
    leerNotificacion: (model) => Axios.post(`${RUTA}/leer-notificacion`, model)

}

export default END_POINTS
