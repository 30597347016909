<template>
    <child />
</template>

<script>
export default {
}
</script>

<style lang="scss" scoped>
</style>
