<template>
    <div class="main-layout">
        <div class="position-relative app-wrapper" :style="`background-image: url(${parametros.fondo_firmado});background-size:cover;`">
            <div class="contenedor">
                <child />
            </div>
        </div>
    </div>
</template>

<script>
import '../assets/sass/app.scss'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'LginLayout',
    computed: {
        ...mapGetters({
            parametros: `auth/parametros`,
        }),
    },
    mounted(){
        this.getParametros()
    },
    methods:{
        ...mapActions({
            getParametros: `auth/getParametros`,
        }),
    }
}
</script>
<style lang="scss" scoped>

main{
    /* padding-left: 65px !important */
}
.app-wrapper {
    font-family: 'Segoe UI';
    color: var(--color-5d);
    height: 100vh;
    width: 100vw;
    margin: 0px !important;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: stretch;

    & > .full-height-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1 1 auto;

        .conf-section {
            flex: 1 1 auto;
            margin: 1.5rem 1rem;
            padding: 1rem;
        }

    }
}
.contenedor{
    height: 100vh;
    width: 100vw;
    margin: 0px;
    box-shadow: 0 3px 6px 0 #00000029;
    /* border-radius: 12px 12px 12px 12px; */

}
</style>
