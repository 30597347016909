<template>
    <section class="modal-ver-observacion">
        <Modal ref="ver_archivo" titulo="Imagen" tamano="modal-xl" :no-aceptar="true" cerrar noCancelar>
            <div class="row mx-0 my-3 justify-center" oncontextmenu="return false" onkeydown="return false">
                <img :src="ruta" alt="" class="imagen m-auto" />
            </div>
        </Modal>
    </section>
</template>

<script>
export default {
    data(){
        return {
            ruta:''
        }
    },
    methods: {
        toggle(ruta){
            this.ruta=ruta
            this.$refs.ver_archivo.toggle()
        },
    }
}
</script>

<style lang="scss" scoped>
.modal-ver-observacion{
    .imagen{
        max-height: 66vh;
        max-width: 90%;
    }

}
</style>
