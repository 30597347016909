import axios from 'axios';

const API_PREFIX = 'general';

const ENDPOINTS = {
    obtenerAvanceCursos: () => axios(`${API_PREFIX}/obtener-avance-cursos`),
    descargar: (ruta) => axios(`descargar/${ruta}`,{
        responseType: 'blob',
    }),
};

export default ENDPOINTS;
