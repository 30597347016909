<template>
    <div style="height:85px;">
        <nav class="navbar  border-bottom d-middle text-white">
            <img src="/img/logohunters.png" class="obj-cover ml-4" style="max-width:100%;height:50px;" />
            <div class="d-flex align-items-center justify-content-end mb-2 ">
                <router-link :to="{ name: 'home', }" class="ml-auto">
                    <div class="col-auto mx-3 text-gris mt-1 ">
                        <i class="icon-home f-20 text-general" /> 
                        <span>
                            Mis Cursos
                        </span>
                    </div>
                </router-link>

                <router-link v-if="$usuario.rol === 2" :to="{name:'configurar.usuarios', }" class="ml-auto"> 
                    <div class="col-auto mx-3 text-gris">
                        <i class="icon-account f-26 text-general" style="position: relative; top:2px" /> Usuarios
                    </div>
                </router-link>
                <router-link v-if="$usuario.rol === 2" :to="{name:'configurar.cursos', }" class=""> 
                    <div class="col-auto mx-3 text-gris ">
                        <i class="icon-cog f-25 text-general" style="position: relative; top:2px" /> Configurar Cursos
                    </div>
                </router-link>
                <div class="col-auto mx-3 text-gris cr-pointer d-flex align-items-baseline mt-2" @click="verNotificaciones()">
                    <el-badge v-if="contador" :value="contador">
                        <div>
                            <i class="icon-bell-alt text-general f-20 mr-2" />
                        </div>
                    </el-badge>
                    Notificaciones  
                </div>
                <div class="col-auto mx-3" style="margin-top: 11px">
                    <p class="br-5 px-2 cr-pointer text-gris" @click="logout">Salir</p>
                </div>  
            </div>
            <!-- <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="icon-menu" />
            </button> -->
        </nav>
        <modal-notificaciones ref="Notificaciones" />
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Notificaciones from '~/services/cursos/notificaciones';
export default {
    data(){
        return {
        };
    },
    computed: {
        ...mapGetters({
            contador: 'notificaciones/getNoVistas'
        })
    },
    mounted(){
        this.contarNotificaciones()
    },
    methods: {
        iniciarSesion(){
        },
        logout(){
            this.$store.dispatch('auth/intentarCerrarSesion')
            this.$router.push({name:'login'})
        },
        verNotificaciones(){
            this.$refs.Notificaciones.toggle();
        },
        async contarNotificaciones(){
            const { data: {data: noVistas} } = await Notificaciones.contarNotificaciones()
            this.$store.commit('notificaciones/setNoVistas', noVistas)
        }
    }
}
</script>

<style lang="css" scoped>
.navbar{
    position: fixed;
    width:100vw;
    top: 0px;
    height: 65px;
    z-index: 5;
    background: #fff;
    box-shadow: 0 3px 6px 0 #00000029;
    /* border-radius: 0 0 8px 8px; */
}
.hover-i li:hover{
    background: #471F9B;
    border-radius: 12px;
}
</style>
