export default [
    {
        path: '/configurar/sistema',
        name: 'configurar.sistema',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/sistema').then(m => m.default || m),
    },
    {
        path: '/configurar/usuarios',
        name: 'configurar.usuarios',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/usuarios').then(m => m.default || m),
        // redirect: {name: 'configurar.usuarios.e-learning'},
        children: [
            {
                path: 'elearning/:id_user',
                name: 'configurar.usuarios.e-learning',
                component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/usuarios/components/contentElearning').then(m => m.default || m),
            },
            {
                path: 'mac/:id_user',
                name: 'configurar.usuarios.mac',
                component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/usuarios/components/contentMac').then(m => m.default || m),
            },
        ]
    },
    {
        path: '/configurar/usuarios/crear-editar',
        name: 'configurar.sistema.crear-editar',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/usuarios/crearEditar').then(m => m.default || m),
    },
    {
        path: '/configurar/cursos',
        name: 'configurar.cursos',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/cursos').then(m => m.default || m),
    },
    {
        path: '/configurar/perfil',
        name: 'configurar.perfil',
        component: () => import(/* webpackChunkName: "configurar" */ '../../pages/configurar/perfil').then(m => m.default || m),
    }
]
