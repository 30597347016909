
import General from '../../services/general'

export const state = {
    avance:{
        cursos:0,
        proceso:0,
        reponsable:0,
    }
}

export const getters = {
    avance: state => state.avance,
}

export const mutations = {
    setAvance: (state,payload) => {
        state.avance = payload;
    },

}

export const actions = {
    async actionObtenerAvanceCursos({commit}){
        const { data: { data } } = await General.obtenerAvanceCursos()
        commit("setAvance", data.avance);
    }

}
