<template>
    <modal
    ref="modalSiNo"
    :titulo="titulo"
    no-guardar
    no-aceptar
    no-cancelar
    :btns="btns"
    @accion="accion"
    >
        <div class="container">
            <div class="my-3 text-muted f-14 justify-center text-center px-3">
                {{ mensaje }}
            </div>
            <slot />
        </div>
    </modal>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        },
        mensaje: {
            type: String,
            default: ''
        }
    },
    data(){
        return{
            btns:[
                {
                    texto: 'No',
                    color: 'btn-eliminar bg-whitesmoke br-10',
                    accion: false,
                    cargando:false
                },
                {
                    texto:'Si',
                    color: 'btn-general br-10',
                    accion:true,
                    cargando:false
                }
            ]
        }
    },
    methods: {
        toggle(){
            this.$refs.modalSiNo.toggle()
        },
        accion(val){
            this.$emit('accion',val)
            if(val){
                let that = this
                this.btns[1].cargando = true
                setTimeout((function(){
                    that.btns[1].cargando = false
                }), 1000);
            }
        },

    }
}
</script>
