<template>
    <div class="main-layout" style="background-image: url(/img/backgrounds/background_home.jpg);background-size:cover;">
        <navbar />
        <div class="position-relative app-wrapper">
            <div class="contenedor border bg-white">
                <child />
            </div>
        </div>
        <menuLateral />
    </div>
</template>

<script>
import '../assets/sass/app.scss'
export default {
    name: 'MainLayout',
}
</script>
<style lang="scss" >

main{
    padding-left: 65px !important
}
.app-wrapper {
    font-family: 'Segoe UI';
    color: var(--color-5d);
    height: calc(100vh - 85px);
    width:calc(100vw - 420px);
    margin-left: auto;
    // z-index: 1;
    display: flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: stretch;

    & > .full-height-wrapper {
        display: flex;
        flex-flow: column nowrap;
        align-items: stretch;
        justify-content: flex-start;
        flex: 1 1 auto;

        .conf-section {
            flex: 1 1 auto;
            margin: 1.5rem 1rem;
            padding: 1rem;
        }

    }
}
.contenedor{
    /* min-height: 95%; */
    height: calc(100% - 28px);
    width: 97%;
    /* margin: auto 0; */
    box-shadow: 0 3px 6px 0 #00000029;
    border-radius: 12px 12px 12px 12px;

}
</style>
