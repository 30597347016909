import store from "~/store";

const VUEX_RUTA = "auth";


const puedeConsultarUsuario = (validaciones) => !validaciones.includes(false);
const redireccionaLogin = ({ name }) => name === "login";

export default async(to, from, next) => {
    const datosSesion = [
        !store.getters[`${VUEX_RUTA}/existeUsuario`],
        store.getters[`${VUEX_RUTA}/existeToken`],
    ];

    try {
        store.commit(`${VUEX_RUTA}/cambiarColorSistema`);
        const seDirigeAlLogin = redireccionaLogin(to);
        const necesitaConsultarUsuario = puedeConsultarUsuario(datosSesion);

        if (seDirigeAlLogin && !necesitaConsultarUsuario) return next();
        
        if (necesitaConsultarUsuario) await store.dispatch(`${VUEX_RUTA}/consultarUsuario`);

        if (!seDirigeAlLogin) return next();


        return next({ name: `home` });
    } catch (error){
        console.error(error);
    }

    next();
};
