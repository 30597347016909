<template>
    <el-drawer :visible.sync="drawer" class="modal-mensaje">
        <template v-slot:title>
            <div v-if="titulo" class="row mx-0 f-600 f-19 j-center a-center">
                {{ titulo }}
            </div>
        </template>
        
        <slot />
    </el-drawer>
</template>

<script>
export default {
    props: {
        titulo: {
            type: String,
            default: ''
        }
    },
    data(){
        return {
            drawer: false,
        };
    },
    methods:{
        toggle(){
            this.drawer = !this.drawer
        }
    }
}
</script>
