export const state = {
    noVistas : 0,
}
export const getters = {
    getNoVistas: state => state.noVistas,
}
export const mutations = {
    setNoVistas(state, datos){
        state.noVistas = datos
    }
}
