export default [
    {
        path: '/responsable/cursos/:id_curso',
        name: 'responsable.ver',
        component: () => import(/* webpackChunkName: "Responsable" */ '../../pages/responsable/ver').then(m => m.default || m),
        redirect: {name: 'responsable.cursos.temas'},
        children: [
            {
                path: 'temas',
                name: 'responsable.cursos.temas',
                component: () => import(/* webpackChunkName: "Responsable" */ '../../pages/responsable/temas').then(m => m.default || m),
            },
            {
                path: 'entregas',
                name: 'responsable.cursos.entregas',
                component: () => import(/* webpackChunkName: "Responsable" */ '../../pages/responsable/entregas').then(m => m.default || m),
            },
            {
                path: 'calificadas',
                name: 'responsable.cursos.calificadas',
                component: () => import(/* webpackChunkName: "Responsable" */ '../../pages/responsable/calificadas').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/responsable/curso/detalle/:id_tema',
        name: 'responsable.curso.detalle',
        component: () => import(/* webpackChunkName: "Responsable" */ '../../pages/responsable/detalle/index').then(m => m.default || m),
        redirect: {name: 'responsable.curso.detalle.temas'},
        children: [
            {
                path: 'temas',
                name: 'responsable.curso.detalle.temas',
                component: () => import(/* webpackChunkName: "Responsable" */ '../../pages/responsable/detalle/temas').then(m => m.default || m),
            },
            {
                path: 'calificaciones',
                name: 'responsable.curso.detalle.calificaciones',
                component: () => import(/* webpackChunkName: "Responsable" */ '../../pages/responsable/detalle/calificaciones').then(m => m.default || m),
            }
        ]
    },
    {
        path: '/configurar/carpeta/ver/:id_carpeta?',
        name: 'configurar.carpeta.ver',
        component: () => import(/* webpackChunkName: "Responsable" */ '../../pages/responsable').then(m => m.default || m),
    }
]