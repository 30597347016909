<template>
    <div class="d-flex mx-0 px-3 shadow-head-home">
        <div class="col pr-0 f-600 text-gris f-22 d-middle pl-4 header-item1 " style="min-width:380px;">
            <i class="icon-arrow-left text-general f-22 cr-pointer mx-1" @click="irAtras" />
            {{ title }}
        </div>
        <div class="header-item2">
            <div class="row mx-0">
                <slot />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:{
        title:{
            type: String,
            default: 'Undefined Title'
        },
        rutaAtras:{
            type: String,
            default: ''
        },
        params:{
            type: Object,
            default: () => {}
        },
        customBack: {
            type: Boolean,
            default: false
        }
    },
    data(){
        return{

        }
    },
    methods: {
        irAtras(){
            /* console.log(window.history); */
            console.log(this.$router);
            if (this.customBack){
                this.$emit('back')
            } else {
                if (this.rutaAtras){
                    this.$router.push({ name: this.rutaAtras, params: this.params })
                }else{
                    this.$router.back()
                    /* this.$router.go(-2) */
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.shadow-head-home{
  box-shadow: 0px 3px 6px #0000001A;
  border-radius: 12px 12px 0px 0px;
  align-items: center;
  padding-bottom: 10px;
  padding-top: 5px;
  z-index: 25;
  flex-wrap: wrap;
}
@media  screen and (max-width: 1306px) {
    .shadow-head-home{
        padding-top: 10px;
    }
    .header-item1{
        order: 2;
    }
    .header-item2{
        order: 1;
        margin-left: 10px;
    }
}
</style>