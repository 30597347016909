import general from "~/mixins/general";
import CursoDetalle from "~/services/cursos/detalle_responsable";

export const state = {
    infoCurso: {},
    rutas: [
        {
            titulo: 'Temas',
            name: 'responsable.cursos.temas'
        },
        {
            titulo: 'Entregas',
            name: 'responsable.cursos.entregas',
            cant_entregas: 0
        },
        {
            titulo: 'Calificadas',
            name: 'responsable.cursos.calificadas',
            cant_entregas: 0
        }
    ],
}

export const getters = {
    getInfoCurso: ({infoCurso}) => infoCurso,
    getRutas: ({rutas}) => rutas,
}

export const mutations = {
    setInfoCurso: (state, payload) => {
        state.infoCurso = payload;
    },
    setCantEntr: (state, payload) => {
        state.rutas[1].cant_entregas = payload;
    }
}

export const actions = {
    async fetchCursos({commit}, idCurso){
        try {
            const { data: { data } } = await CursoDetalle.getCurso(idCurso)
            commit('setInfoCurso', data.curso)
            commit('setCantEntr', data.curso.hechas_count)
        } catch (error){
            general.methods.error_catch(error)
        }
    }

}
