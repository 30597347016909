<template>
    <div class="cont-menu ">
        <div class="menu-lateral border px-4 position-relative bg-white custom-scroll">
            <router-link :to="{name: 'configurar.perfil'}">
                <i class="icon-cog f-22 text-general position-absolute cr-pointer" style="right:10px;top:5px;z-index:10;" />
            </router-link>
            <div class="row mx-0">
                <div class="col px-0 text-center pt-4">
                    <img :src="$usuario.foto" class="obj-cover rounded-circle" width="180" height="180" />
                </div>
                <div class="col-12 px-0 mt-3">
                    <p class="text-general my-2 f-600">
                        {{ $usuario.nombre }}
                    </p>
                    <p class="text-gris my-2">
                        {{ $usuario.cargo }}
                    </p>
                    <p class="text-gris my-2">
                        {{ $usuario.edad }} años
                    </p>
                    <p class="text-gris my-2">
                        vinculación a la compañía:
                    </p>
                    <p class="text-gris my-2">
                        {{ anios }}
                    </p>
                    <p class="text-gris my-2">
                        {{ $usuario.correo }}
                    </p>
                </div>
            </div>
            <div class="row mx-0">
                <div class="col my-1 pl-0">
                    <div class="w-100 h-100 border br-12 ">
                        <div class="row mx-0 text-general f-600 f-22 j-center">
                            Mis cursos
                        </div>
                        <hr class="border mx-4 my-2 mb-1" />
                        <div class="row mx-0 text-gris f-22 j-center">
                            {{ avance.cursos }}
                        </div>
                    </div>
                </div>
                <div class="col my-1 pr-0">
                    <div class="w-100 h-100 border br-12 ">
                        <div class="row mx-0 text-general f-600 f-22 j-center">
                            En proceso
                        </div>
                        <hr class="border mx-4 my-2 mb-1" />
                        <div class="row mx-0 text-gris f-22 j-center">
                            {{ avance.proceso }}
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="avance.reponsable" class="col-12 my-3 px-0">
                <div class="w-100 border  br-12 bg-f8">
                    <div class="row mx-0 py-2">
                        <div class="col text-general f-400">
                            Responsable de
                        </div> 
                        <!-- <div class="col-auto px-0 border h-75"></div> -->
                        <div class="col-auto text-gris px-5" style="border-left:1px solid #ACACAC;">
                            {{ avance.reponsable }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    data(){
        return {
            rutaActiva: '',
            menus: [
                { titulo: 'Configuración', ruta: 'configurar', icon: 'icon-cog', cant: 0 },
            ]
        }
    },
    computed: {
        ...mapGetters({
            avance: 'general/avance'
        }),
        isActive(){
            return this.$route.name
        },
        anios(){
            if(!this.$usuario.edad){
                return 'No registra edad'
            }
            return `${this.$usuario.antiguedad} ${this.$usuario.antiguedad == 1 ? 'año' : 'años'}`
        }
    },
    mounted(){
        this.obtenerAvanceCursos()
    },
    methods: {
        menuSeleccionado(){
            return this.menus[0]
        },
        obtenerAvanceCursos(){
            this.$store.dispatch('general/actionObtenerAvanceCursos')

        },
        ir(ruta){
            this.rutaActiva=ruta
            this.$router.push({ name: ruta })
        },
        subIsActive(input){
            const paths = Array.isArray(input) ? input : [input]
            return paths.some(path => {
                return this.$route.path.indexOf(path) === 0 || this.$route.path.indexOf(path) === 1 || this.$route.name === path
            })
        },
    }
}
</script>

<style lang="scss" scoped>
.cont-menu{
    font-family: 'Segoe UI';
    position: fixed;
    top:85px;
    width: 420px;
    height: calc(100vh - 85px);
    //background-color: red;
    z-index: 0;
    display: flex;
    justify-content: center;
    /* align-items: center; */
}
.menu-lateral{
    width:360px;
    /* Oldi Height */
    /* height: calc(100% - 28px); */
    height: calc(100% - 28px);
    overflow: auto !important;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 12px;
}
.badge{
    position: absolute;
    top: 2px;
    left: 35px;
    border-radius: 20px!important;
    border: 1px solid #fff;
    color: #fff;
    background: linear-gradient(to right, #FF9D32 5%, #FF2525)
}
.menu{
    a{
        &:hover{
            background:#ffffff50;
        }
        width:56px;height:56px;
        &.router-link-exact-active.router-link-active{
            background:#ffffff50;
        }
        &:hover{
            text-decoration: none !important;
        }
    }
}
</style>
