export const state = {
    currentUser: {},
}

export const getters = {
    getCurrentUser: ({currentUser}) => currentUser,
}

export const mutations = {
    setCurrentUser: (state, payload) => {
        state.currentUser = payload
    }

}

