<template>
    <el-drawer :visible.sync="drawer" class="modal-mensaje">
        <template #title>
            <div class="row mx-0 f-600 f-19 text-center j-center a-center my-3">
                <h4 class="col px-0 cr-pointer text-gris">
                    Notificaciones
                </h4>
            </div>
        </template>


        <div class="col">
            <button v-for="(notificacion, i) in notificaciones" :key="i" :class="['w-100 bg-white border br-12 my-3 p-1 ml-0 mr-3 ', {'leido':notificacion.leida == 2}]" @click="notificacionLeida(notificacion)">
                <div class="row mx-0  cr-pointer">
                    <img :src="notificacion.foto" class="rounded-circle obj-cover my-auto mx-2" width="50" height="50" />
                    <div class="col text-left">
                        <h6 class="f-12 font-weight-bold">
                            {{ notificacion.titulo }}
                        </h6>
                        <div class="col p-0  justify-content-between f-9 text-muted">
                            <p class="mr-1">{{ notificacion.texto }}</p>
                            <!-- <i>{{ notificacion.texto }}</i> -->
                            <span>{{ notificacion.fechaCreacion }}</span>
                        </div>
                    </div>
                </div>
            </button>
        </div>






        <!-- <div class="custom-scroll overflow-auto" style="height:calc(100vh - 130px)">
            <button v-for="(notificacion, i) in notificaciones" :key="i" :class="['row mx-3 j-center bg-white border br-12 my-3', {'leido':notificacion.leida == 2}]" @click="notificacionLeida(notificacion)">
                <div class="col-12 pt-2 pb-1 d-middle">
                    <h6 class="text-gris f-18 f-600">
                        {{ notificacion.titulo }}
                    </h6>
                </div>
                <div class="col-12 px-0">
                    <div class="row mx-0">
                        <div class="col-auto ml-4 mr-2 px-0">
                            <div class="line-message position-relative" style="width:10px;height:15px;">
                                <li class="text-general position-absolute" style="width:10px;height:10px;right:-10px;top:1px;" />
                            </div>
                        </div>
                        <p class="col-auto px-0 pl-3 m-0">
                            {{ notificacion.texto }}
                        </p>
                    </div>
                </div>
                <div class="col-12 my-3">
                    <div class="row mx-0">
                        <div class="col">
                            <img :src="notificacion.foto" width="45" height="45" class="position-absolute obj-cover rounded-circle border-grey" style="top:-13px;left:-9px;" />
                        </div>
                        <span class="col text-gris text-right">
                            {{ notificacion.fechaCreacion }}
                        </span>
                    </div>
                </div>
            </button>        
        </div> -->
    </el-drawer>
</template>

<script>
import Notificaciones from '~/services/cursos/notificaciones';
export default {
    data(){
        return {
            drawer: false,
            notificaciones: []
        };
    },
    methods:{
        toggle(){
            this.drawer = !this.drawer
            this.listarNotificaciones()
        },

        async listarNotificaciones(){
            try {
                const { data } = await Notificaciones.listarNotificaciones()
                this.notificaciones = data.data
            } catch (e){
                this.error_catch(e)
            }
        },

        async notificacionLeida(notificacion){
            try {

                let model ={
                    id:notificacion.id
                }
                const { data } = await Notificaciones.leerNotificacion(model)
                const { data: { data: noVistas } } = await Notificaciones.contarNotificaciones()  
                this.$store.commit('notificaciones/setNoVistas', noVistas)
                this.listarNotificaciones()
                
            } catch (e){
                this.error_catch(e)
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.border-text{
    border-left: 1px solid #00125A;
    border-bottom: 1px solid #00125A;
}
.ticket-name{
    background-color: #D9DCE7;
    width: 150px;
    color: #838486;
    border-radius: 0px 20px 20px 0px;
    img{
        left: 0px;
        top: -15px;
    }
}
.line-message{
    border-left: 1px solid var(--color-general) !important;
    border-bottom: 1px solid var(--color-general) !important;
}
.leido{
    background-color: #dedede !important;
}
</style>
