import axios from 'axios';

const API_PREFIX = 'lector';

const ENDPOINTS = {
    crearInteraccion: (params) => axios.post(`${API_PREFIX}/crear-interaccion`, params),

    getCurso: (idCurso) => axios(`${API_PREFIX}/curso/${idCurso}`),
    getAvanceCurso: (idCurso) => axios(`${API_PREFIX}/avance-curso/${idCurso}`),
    descargarRecursos: (params) =>
        axios(`${API_PREFIX}/descargar-recursos`, {
            params,
            //responseType: "arraybuffer",
        }),

    getTemas: (idCurso) => axios(`${API_PREFIX}/get-temas-curso/${idCurso}`),
    calificarTema: (params) => axios.post(`${API_PREFIX}/calificar-tema`, params),
    
    getEntregas: (idCurso) => axios(`${API_PREFIX}/get-entregas-curso/${idCurso}`),
    getEntregasAdjuntos: (idEntregaHecha) => axios(`${API_PREFIX}/get-entregas-adjuntos/${idEntregaHecha}`),
    enviarEntrega: (params) => axios.post(`${API_PREFIX}/enviar-entrega`, params),
    editarEntrega: (params) => axios.post(`${API_PREFIX}/editar-entrega`, params),
    eliminarEntregaHechaAdjunto: (id) => axios.delete(`${API_PREFIX}/eliminar-entrega-hecha-adjunto/${id}`),
    
};

export default ENDPOINTS;
