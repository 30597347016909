<template>
    <Modal ref="ver_video" titulo="Video" tamano="modal-lg" :no-aceptar="true">
        <div class="container">
            <div class="row">
                <div class="col text-center">
                    <video
                    id="videoGBP"
                    ref="videoGBP2"
                    style="max-width:92%;max-height:66vh;"
                    :src="ruta"
                    controls
                    controlslist="nodownload"
                    oncontextmenu="return false"
                    onkeydown="return false"
                    @blur="eventoBlur"
                    />
                </div>
            </div>
        </div>
    </Modal>
</template>
<script>
export default {
    data(){
        return {
            ruta:'',
        }
    },
    methods: {
        async ModalVideo(url){
            this.ruta = url
            this.$refs.ver_video.toggle();
        },
        eventoBlur(){
            var myVideo = this.$refs.videoGBP2
            if (myVideo.paused)
                myVideo.play();
            else
                myVideo.pause();
        },
    }
}
</script>
<style lang="css" scoped>
</style>
