import axios from 'axios';

const API_PREFIX = "responsable";

const ENDPOINTS = {
    getCurso: (idCurso) => axios(`${API_PREFIX}/curso/${idCurso}`),
    getTemasCurso: (idCurso) => axios(`${API_PREFIX}/get-temas-curso/${idCurso}`),    
    getTema: (idTema) => axios(`${API_PREFIX}/get-tema/${idTema}`),    
    getTemaRecursos: (idTema) => axios(`${API_PREFIX}/get-tema-recursos/${idTema}`),    
    getTemaCalificaciones: (idTema) => axios(`${API_PREFIX}/get-tema-calificaciones/${idTema}`),    
    

};

export default ENDPOINTS;
