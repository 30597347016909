
import Cursos from '~/services/cursos/ver_cursos'

export const state = {
    avance: 0,
    entregas: 0,
    hechas: 0,
}

export const getters = {
    avance: state => state.avance,
    entregas: state => state.entregas,
    hechas: state => state.hechas,
}

export const mutations = {
    setAvance: (state,payload) => {
        state.avance = payload
    },
    setEntregas: (state,payload) => {
        state.entregas = payload
    },
    setHechas: (state,payload) => {
        state.hechas = payload
    },
}

export const actions = {
    async actionObtenerAvanceUsuario({ commit },payload){
        const { data: { data } } = await Cursos.getAvanceCurso(payload)
        commit('setAvance',data.avance)
        commit('setEntregas',data.entregas)
        commit('setHechas',data.hechas)
    }

}
