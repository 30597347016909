<template>
    <div
    ref="el-visor-pdf"
    class="_df_custom"
    webgl="false"
    download="false"
    :source="source"
    />
</template>

<script>
export default {
    layout:'base',
    middleware:['auth'],
    data(){
        return {
            source: ''
        }
    },

    mounted(){
        this.set_pdf('https://learning-essi.s3.amazonaws.com/descargables/d1a95383-14db-4992-99c7-1cc6ac57de33.pdf')  
    },
    methods:{
        set_pdf(source){
            this.source = this.$route.query.nombre
            this.$nextTick(()=>{
                this.$refs['el-visor-pdf'].click()
            })
        },
        getRuta(){

        }
    }
}
</script>
