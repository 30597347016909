export default [
    {
        path: '/mis-cursos/carpeta/ver/:id_carpeta',
        name: 'mis-cursos.carpeta.ver',
        component: () => import('../../pages/misCursos/carpetas/ver').then(m => m.default || m),
    },
    {
        path: '/mis-cursos/mosaico',
        name: 'mis-cursos.mosaico',
        component: () => import(/* webpackChunkName: "mis-cursos" */ '../../pages/misCursos/mosaico').then(m => m.default || m),
    },
    {
        path: '/mis-cursos/editar/carpeta/:id_carpeta?',
        name: 'mis-cursos.editar.carpeta',
        component: () => import('../../pages/misCursos/carpetas/editar').then(m => m.default || m),
    },
    {
        path: '/mis-cursos/ver/curso/:id_curso',
        name: 'mis-cursos.ver.curso',
        component: () => import('../../pages/misCursos/cursos/verCurso').then(m => m.default || m),
        redirect: {name : 'curso.temas'},
        children: [
            {
                path: 'Temas',
                name: 'curso.temas',
                component: () => import('../../pages/misCursos/cursos/temasCurso').then(m => m.default || m),
            },
            {
                path: 'Entregas',
                name: 'curso.entregas',
                component: () => import('../../pages/misCursos/cursos/entregaCurso').then(m => m.default || m),
            },            
        ]
    },   
    {
        path: '/mis-cursos/editar/curso/:id_curso',
        name: 'mis-cursos.editar.curso',
        component: () => import('../../pages/misCursos/cursos/editar/editar').then(m => m.default || m),
        redirect: {name : 'mis-cursos.editar.curso.tema'},
        children: [
            {
                path: 'temas',
                name: 'mis-cursos.editar.curso.tema',
                component: () => import('../../pages/misCursos/cursos/editar/temas').then(m => m.default || m),
            },
            {
                path: 'lectores',
                name: 'mis-cursos.editar.curso.lectores',
                component: () => import('../../pages/misCursos/cursos/editar/lectores').then(m => m.default || m),
            },  
            {
                path: 'entregas',
                name: 'mis-cursos.editar.curso.entregas',
                component: () => import('../../pages/misCursos/cursos/editar/entregas').then(m => m.default || m),
            },            
            {
                path: 'responsables',
                name: 'mis-cursos.editar.curso.responsables',
                component: () => import('../../pages/misCursos/cursos/editar/responsables').then(m => m.default || m),
            }     
        ]
    },
    {
        path: '/mis-cursos/editar/tema/:id_tema',
        name: 'mis-cursos.edita.tema',
        component: () => import('../../pages/misCursos/cursos/editar/editarTema').then(m => m.default || m),
    },
    {
        path: '/visor',
        name: 'archivos.visor',
        component: () => import('../../components/visorPdf').then(m => m.default || m)
    },
]