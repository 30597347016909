
import Cursos from '~/services/cursos/cursos_lector'

export const state = {
    carpetas: [],

}

export const getters = {
    carpetas: state => state.carpetas,
}

export const mutations = {
    setCarpetas: (state,payload) => {
        state.carpetas = payload
    },

}

export const actions = {
    async actionObtenerCarpetasUsuario({commit}){
        const {data:{data}} = await Cursos.obtenerCursosUsuario()
        commit('setCarpetas',data.carpetas)
    }

}
